import { useState } from "react";

export function useNotify() {
  const [result, setResult] = useState<
    | {
        status: "OK" | "ERROR";
        parking?: {
          referenceNumber: number;
          licensePlate: string;
          periodEnd: string;
          parkingZoneName: string;
          periodBegin: string;
        };
        registration: string;
        message?: string;
        error: "None" | "Parking";
      }
    | undefined
  >(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  const notify = (person: Person, registration: string) => {
    setLoading(true);

    fetch(
      `${process.env.REACT_APP_RAREWINE_API_URL}reception/sendnotification`,
      {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: person.email,
          number: person.number,
          registration: registration,
          notifyToken:
            "emapax4X3UeYJUrUox56LgFXKplJxJCl7Q2XcU5DnLT8cpCysjoTqQ==",
        }),
      },
    )
      .then((x) => x.json())
      .then((json) => setResult(json))
      .catch((e) =>
        setResult({
          status: "ERROR",
          registration: "",
          message: e,
          error: "None",
        }),
      )
      .finally(() => setLoading(false));
  };

  return { loading, result, notify };
}
