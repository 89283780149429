import { Avatar, Button, Card, Col, Modal, Row, Spin } from "antd";
import React, { useRef, useState } from "react";
import Keyboard from "react-simple-keyboard";
import { BackButton } from "../../../components/back-button/back-button";
import { KeyboardWrapper } from "../../../components/keyboard-wrapper/KeyboardWrapper";
import { Trans } from "../../../components/Trans";
import { useCultureCode, useLocale } from "../../../context/IntlContext";
import { useClickOutside } from "../../../hook/useClickOutside";
import { useNotify } from "../../../hook/useNotify";
import { Pages } from "../../frontpage/frontpage-view";
import style from "./employee.module.scss";

export function EmployeeCard(props: {
  employee: any | undefined;
  onBackedClicked: (page: Pages) => void;
}) {
  const [needParking, setNeedParking] = useState<boolean>();
  const [readyToNotify, setReadyToNotify] = useState(false);
  const [visibleKeyboard, setVisibleKeyboard] = useState(false);
  const keyboard = useRef<typeof Keyboard | null>(null);
  const [registrationNumber, setRegistrationNumber] = useState<string>("");
  const [showInvalidPlateModal, setShowInvalidPlateModal] = useState(false);
  const wrapperRef = useRef(null);
  const { notify, loading, result } = useNotify();

  const locale = useLocale();
  const cultureCode = useCultureCode();

  useClickOutside(wrapperRef, () => setVisibleKeyboard(false));

  function validateNumberPlate(plate: string) {
    const didParseValidationTest = /^[A-Za-z]{2}[0-9]{5}$/.test(plate);

    if (didParseValidationTest) {
      notify(props.employee!, plate);
      setReadyToNotify(true);
    } else {
      setShowInvalidPlateModal(true);
    }
  }

  const placeHolderPicture =
    "https://www.rarewine.dk/wp-content/uploads/636289323828937135_Afterlight_Edit-300x300.jpg";

  if (props.employee) {
    return (
      <>
        <BackButton
          previousPage={Pages.Carousel_Employee}
          onClicked={props.onBackedClicked}
        />
        <Row justify="center" style={{ width: "100%" }}>
          <Col span={16}>
            <Card className={style.card}>
              <Row>
                <Col span={5}>
                  <Avatar
                    className={style.avatar}
                    src={
                      props.employee.picture
                        ? props.employee.picture
                        : placeHolderPicture
                    }
                  />
                </Col>
                <Col span={17} style={{ margin: "auto" }}>
                  <p className={style.name}>{props.employee.name}</p>
                  <p className={style.position}>
                    {props.employee.position[locale]}
                  </p>
                </Col>
                <Col span={24} style={{ marginTop: 20 }}>
                  <Row gutter={[16, 16]} justify="center">
                    {needParking === undefined && readyToNotify === false && (
                      <>
                        <Col span={12}>
                          <Button
                            onClick={() => {
                              setNeedParking(true);
                              setVisibleKeyboard(true);
                            }}
                            className={style.btn}
                            size="large"
                            block
                          >
                            <Trans msg={"buttonParkingLicense"} />
                          </Button>
                        </Col>
                        <Col span={12}>
                          <Button
                            onClick={() => setNeedParking(false)}
                            className={style.btn}
                            size="large"
                            block
                          >
                            <Trans msg={"buttonNoParkingLicense"} />
                          </Button>
                        </Col>
                      </>
                    )}
                    {needParking === false && readyToNotify === false && (
                      <>
                        <Col span={5}>
                          <Button
                            onClick={() => setNeedParking(undefined)}
                            size="large"
                            className={style.btn}
                            block
                          >
                            <Trans msg={"cancel"} />
                          </Button>
                        </Col>
                        <Col span={12}>
                          <Button
                            className={style.btn}
                            onClick={() => {
                              setReadyToNotify(true);
                              notify(props.employee!, registrationNumber);
                            }}
                            size="large"
                            block
                          >
                            {`Notify ${
                              props.employee.name.split(" ")[0]
                            } of your arrival`}
                          </Button>
                        </Col>
                      </>
                    )}
                    {needParking && readyToNotify === false && (
                      <Row
                        style={{ width: "100%" }}
                        align="bottom"
                        gutter={[16, 16]}
                      >
                        <Col span={16}>
                          <h4 className={style.registrationLabel}>
                            <Trans msg={"registrationLabel"} />
                          </h4>
                          <div
                            ref={wrapperRef}
                            onClick={() => {
                              setVisibleKeyboard(true);
                            }}
                            className={style.fakeInput}
                          >
                            <span className={style.input}>
                              {registrationNumber}
                            </span>
                            {visibleKeyboard && (
                              <div className={style.cursor}></div>
                            )}

                            <KeyboardWrapper
                              onEnter={() => setVisibleKeyboard(false)}
                              keyboardRef={keyboard}
                              onChange={setRegistrationNumber}
                              visible={visibleKeyboard}
                            />
                          </div>
                        </Col>
                        <Col span={8}>
                          <Button
                            block
                            size="large"
                            className={style.btn}
                            onClick={() => {
                              validateNumberPlate(registrationNumber);
                            }}
                          >
                            {`Notify ${props.employee.name.split(" ")[0]}`}
                          </Button>
                        </Col>
                      </Row>
                    )}

                    {readyToNotify && (
                      <>
                        {loading && <Spin size="large" />}
                        {!loading && (
                          <div>
                            {result?.status === "OK" && needParking && (
                              <Row>
                                <h4
                                  className={style.text}
                                  style={{ textAlign: "center", marginTop: 32 }}
                                >
                                  {result.error === "None" && (
                                    <Trans
                                      msg={"notifyResponseWithParking"}
                                      params={{
                                        registration:
                                          result.parking?.licensePlate,
                                        expires: result.parking?.periodEnd
                                          ? new Date(
                                              result.parking?.periodEnd,
                                            ).toLocaleString(cultureCode)
                                          : "",
                                        parkingReference:
                                          result.parking?.referenceNumber,
                                      }}
                                    />
                                  )}

                                  {result.error === "Parking" && (
                                    <Trans msg={"errorParking"} />
                                  )}
                                </h4>
                              </Row>
                            )}

                            {result?.status === "OK" &&
                              needParking === false && (
                                <Row>
                                  {}
                                  <h4
                                    className={style.text}
                                    style={{
                                      textAlign: "center",
                                      marginTop: 32,
                                    }}
                                  >
                                    <Trans msg={"notifyResponseNoParking"} />
                                  </h4>
                                </Row>
                              )}

                            {result?.status === "ERROR" && (
                              <Row>
                                {}
                                <h4
                                  className={style.text}
                                  style={{ textAlign: "center", marginTop: 32 }}
                                >
                                  <Trans
                                    msg={"notifyError"}
                                    params={{
                                      name: props.employee.name.split(" ")[0],
                                      number: props.employee.number,
                                    }}
                                  />
                                </h4>
                              </Row>
                            )}
                          </div>
                        )}
                      </>
                    )}
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Modal
          visible={showInvalidPlateModal}
          width={1000}
          closable={false}
          footer={null}
        >
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <h2 style={{ textAlign: "center" }}>
                <Trans msg={"registrationPlate"} />
              </h2>
              <h1
                style={{ textAlign: "center", fontWeight: "bold", padding: 35 }}
              >
                {registrationNumber.toUpperCase()}
              </h1>
            </Col>
            <Col span={12}>
              <Button
                block
                size="large"
                onClick={() => setShowInvalidPlateModal(false)}
              >
                <Trans msg={"cancel"} />
              </Button>
            </Col>
            <Col span={12}>
              <Button
                block
                style={{ backgroundColor: "#5a1b18", color: "white" }}
                size="large"
                onClick={() => {
                  notify(props.employee!, registrationNumber);
                  setShowInvalidPlateModal(false);
                  setReadyToNotify(true);
                }}
              >
                <Trans msg={"register"} />
              </Button>
            </Col>
          </Row>
        </Modal>
      </>
    );
  } else {
    return <></>;
  }
}
