import { Button, Col, Row, Spin, Typography } from "antd";
import React, { useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useParams } from "react-router-dom";
import { SetLanguage } from "../../components/set-language/set-language";
import { Trans } from "../../components/Trans";
import { IntlContext } from "../../context/IntlContext";
import { useFetch } from "../../hook/useFetch";
import { useInterval } from "../../hook/useInterval";
import { CarouselCategory } from "../notify/categories/carousel_category";
import { CategoryMembers } from "../notify/category_members/category_members";
import { EmployeeCard } from "../notify/employee/employee_card";
import { PostView } from "../post/post-view";
import style from "./frontpage.module.scss";
import { MenuOptions } from "./menu-options";

export enum Pages {
  Mainpage = 0,
  Post = 1,
  Event = 2,
  Groups = 3,
  Carousel_Employee = 4,
  Employee = 5,
}

export function Frontpage() {
  const securityGuid = "e65eda13-12fe-4c36-9414-698973d80c38";
  const receptionListUrl = `${process.env.REACT_APP_BLOBSTORAGE_URL}rare-wine-public/reception_list.json`;

  const {
    data: receptionList,
    refetch: fetchReceptionList,
    error,
    loading,
  } = useFetch<ReceptionList>(receptionListUrl);
  const timeout = 30000;
  const [selectedPerson, setSelectedPerson] = useState<Person>();
  const [selectedCategory, setSelectedCategory] = useState<Category>();
  const [pageRender, setPageRender] = useState<Pages>(3);
  const [language, setLanguage] = useState<Locale>("en");

  const { guid } = useParams();
  const handleOnIdle = () => setPageRender(Pages.Groups);
  useIdleTimer({ timeout, onIdle: handleOnIdle });

  useInterval(
    () => {
      fetchReceptionList();
    },
    // 20 minutes
    1200000,
  );

  if (guid !== securityGuid) {
    return <></>;
  }

  return (
    <>
      <IntlContext.Provider value={language}>
        <Row
          justify="center"
          style={{ maxHeight: "100vh", overflow: "hidden" }}
        >
          <Col span={24} style={{ height: "auto" }}>
            <SetLanguage
              language={language}
              setLanguage={(locale) => setLanguage(locale)}
            />
            <Row justify="center" align="middle">
              <Col>
                <h1 className={style.header}>
                  <Trans msg={"header"} />
                </h1>
              </Col>
            </Row>
            <Row justify="center" align="middle">
              <Col>
                <h4 className={style.subheader}>
                  <Trans msg={"subheader"} />
                </h4>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            {receptionList && !error ? (
              <Row justify="center">
                {Pages.Mainpage === pageRender && (
                  <MenuOptions setPage={setPageRender} />
                )}
                {Pages.Post === pageRender && (
                  <PostView
                    onBackedClicked={(page) => setPageRender(page)}
                    receptionList={receptionList}
                  />
                )}
                {Pages.Groups === pageRender && (
                  <CarouselCategory
                    setPage={setPageRender}
                    receptionList={receptionList}
                    setCategory={setSelectedCategory}
                  />
                )}
                {Pages.Carousel_Employee === pageRender && (
                  <CategoryMembers
                    onBackedClicked={(page) => setPageRender(page)}
                    language={language}
                    receptionList={receptionList}
                    category={selectedCategory}
                    setPerson={setSelectedPerson}
                  />
                )}
                {Pages.Employee === pageRender && (
                  <EmployeeCard
                    onBackedClicked={(page) => setPageRender(page)}
                    employee={selectedPerson}
                  />
                )}
              </Row>
            ) : (
              <Row
                justify="center"
                style={{
                  padding: 32,
                }}
              >
                {loading ? (
                  <Spin size="large" />
                ) : (
                  <Col span={18}>
                    <Row justify="center">
                      <Typography.Text
                        style={{ fontSize: "2em", color: "white" }}
                      >
                        <Trans msg={"loadEmployeesError"} />
                      </Typography.Text>
                    </Row>
                    <Row justify="center">
                      <Button
                        onClick={fetchReceptionList}
                        type="primary"
                        size="large"
                      >
                        <Trans msg={"retry"} />
                      </Button>
                    </Row>
                  </Col>
                )}
              </Row>
            )}
          </Col>
        </Row>
      </IntlContext.Provider>
    </>
  );
}
