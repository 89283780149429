import { BellOutlined } from "@ant-design/icons";
import { Card, Col, Row } from "antd";
import React from "react";
import { Pages } from "./frontpage-view";
import style from "./menu.module.scss";

export function MenuOptions(props: {
  setPage: React.Dispatch<React.SetStateAction<Pages>>;
}) {
  return (
    <Row gutter={[16, 16]}>
      <Col span={12}>
        <Card
          hoverable
          onClick={() => props.setPage(Pages.Groups)}
          className={style.card}
          cover={<BellOutlined style={{ fontSize: "300px", color: "white" }} />}
        >
          <p style={{ color: "white", fontSize: "32px", textAlign: "center" }}>
            Menu 1
          </p>
        </Card>
      </Col>
      <Col span={12}>
        <Card
          hoverable
          className={style.card}
          cover={<BellOutlined style={{ fontSize: "300px", color: "white" }} />}
        >
          <p style={{ color: "white", fontSize: "32px", textAlign: "center" }}>
            Menu 2
          </p>
        </Card>
      </Col>
    </Row>
  );
}
