import { Button, Card, Col, Row } from "antd";
import React, { useState } from "react";
import { BackButton } from "../../components/back-button/back-button";
import { Trans } from "../../components/Trans";
import { Pages } from "../frontpage/frontpage-view";
import style from "./post.module.scss";

export function PostView(props: {
  receptionList: ReceptionList | undefined;
  onBackedClicked: (page: Pages) => void;
}) {
  const [isCalled, setIsCalled] = useState(false);
  const getEmployeesToNotify = () => {
    return props.receptionList!.all_employees.filter((e) => e.deliveryContact);
  };

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <BackButton
        previousPage={Pages.Groups}
        onClicked={props.onBackedClicked}
      />
      <Row
        style={{ width: "100%", height: "100%" }}
        justify="center"
        align="middle"
      >
        <Col span={16} style={{ height: "100%", marginTop: 200 }}>
          <Card className={style.card}>
            {!isCalled && (
              <>
                <p
                  style={{ color: "white", textAlign: "center", fontSize: 32 }}
                >
                  <Trans msg={"postExplanation"} />
                </p>
                <Button
                  block
                  size="large"
                  className={style.btn}
                  onClick={() => {
                    setIsCalled(true);
                    fetch(
                      `${process.env.REACT_APP_RAREWINE_API_URL}Reception/notfiyPost`,
                      {
                        method: "POST",
                        headers: {
                          "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                          NotifyToken:
                            "emapax4X3UeYJUrUox56LgFXKplJxJCl7Q2XcU5DnLT8cpCysjoTqQ==",
                          AllEmployees: getEmployeesToNotify(),
                        }),
                      },
                    );
                  }}
                >
                  <Trans msg={"postCallEmployee"} />
                </Button>
              </>
            )}
            {isCalled && (
              <p style={{ color: "white", textAlign: "center", fontSize: 32 }}>
                <Trans msg={"postCalledEmployee"} />
              </p>
            )}
          </Card>
        </Col>
      </Row>
    </div>
  );
}
