import React from "react";
import da from "../../images/da.png";
import en from "../../images/en.png";
import style from "./set-language.module.scss";

export function SetLanguage(props: {
  language: Locale;
  setLanguage: (locale: Locale) => void;
}) {
  return (
    <div className={style.container}>
      <img
        onClick={() => props.setLanguage("da")}
        className={`${style.flag} ${props.language === "da" && style.active}`}
        src={da}
        alt="Danish flag"
      />
      <img
        onClick={() => props.setLanguage("en")}
        className={`${style.flag} ${props.language === "en" && style.active}`}
        src={en}
        alt="British flag"
      />
    </div>
  );
}
