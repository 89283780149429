import React, { Row, Col } from "antd";
import { useState, MutableRefObject } from "react";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import style from "./keyboard-wrapper.module.scss";

interface IProps {
  onChange: (input: string) => void;
  keyboardRef: MutableRefObject<typeof Keyboard | null>;
  visible: boolean;
  onEnter: () => void;
}

export function KeyboardWrapper(props: IProps) {
  const [layoutName, setLayoutName] = useState("default");

  const onKeyPress = (button: string) => {
    if (button === "{shift}" || button === "{lock}") {
      setLayoutName(layoutName === "default" ? "shift" : "default");
    }
    if (button === "{enter}") {
      props.onEnter();
    }
  };

  if (!props.visible) return <></>;

  return (
    <div className={style.container}>
      <Row justify="center" align="middle">
        <Col span={24}>
          <Keyboard
            keyboardRef={(r) => (props.keyboardRef.current = r)}
            layoutName={layoutName}
            onChange={props.onChange}
            onKeyPress={onKeyPress}
          />
        </Col>
      </Row>
    </div>
  );
}
