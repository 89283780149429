import { useState, useEffect } from "react";

export function useFetch<T>(url: string): {
  data: T | undefined;
  error: any | undefined;
  loading: boolean;
  refetch: () => void;
} {
  const [data, setData] = useState<T | undefined>(undefined);
  const [error, setError] = useState<any | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchData = async () => {
    setLoading(true);
    setError(undefined);

    try {
      const res = await fetch(url, {
        headers: {
          "Cache-Control": "no-cache",
        },
      });
      const json = (await res.json()) as T;
      setData(json);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchData();
    // eslint-disable-next-line
  }, []);

  return { data, error, loading, refetch: fetchData };
}
