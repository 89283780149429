import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Avatar, Carousel, Col, Row, Spin } from "antd";
import React, { useRef } from "react";
import { BackButton } from "../../../components/back-button/back-button";
import { Pages } from "../../frontpage/frontpage-view";
import style from "./category.module.scss";

export function CategoryMembers(props: {
  onBackedClicked: (page: Pages) => void;
  category: Category | undefined;
  receptionList: ReceptionList | undefined;
  language: Locale;
  setPerson: React.Dispatch<React.SetStateAction<Person | undefined>>;
}) {
  const carouselRef = useRef<any>(undefined);
  const personPrPage = 4;
  const renderRows = (employees: Person[]) => {
    const jsx: JSX.Element[] = [];
    for (let i = 0; i < employees.length; i++) {
      if (i % personPrPage === 0) {
        const chunk = employees.slice(i, i + personPrPage);
        const temp = (
          <div key={i}>
            <Row gutter={[16, 16]} justify="center">
              <Col span={16}>
                <Row gutter={[16, 16]}>
                  {chunk.map((person) => (
                    <Col key={person.id} span={12}>
                      <EmployeeCard
                        employee={person}
                        language={props.language}
                        setPerson={props.setPerson}
                        setPage={props.onBackedClicked}
                      />
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
          </div>
        );

        jsx.push(temp);
      }
    }
    return jsx;
  };

  if (props.category && props.receptionList) {
    return (
      <div style={{ width: "100%" }}>
        <BackButton
          previousPage={Pages.Groups}
          onClicked={props.onBackedClicked}
        />
        <Carousel ref={carouselRef} style={{ width: "100%" }} dots={false}>
          {renderRows(
            props.receptionList.all_employees
              .filter((e) => e.category === props.category?.name)
              .sort((a, b) => a.category_order - b.category_order),
          )}
        </Carousel>
        {props.receptionList.all_employees.filter(
          (e) => e.category === props.category?.name,
        ).length > personPrPage && (
          <Row justify="center">
            <Col span={16}>
              <Row>
                <Col span={12}>
                  <LeftOutlined
                    className={style.ArrowLeft}
                    onClick={() => carouselRef.current.prev()}
                  />
                </Col>
                <Col span={12}>
                  <RightOutlined
                    className={style.ArrowRight}
                    onClick={() => carouselRef.current.next()}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      </div>
    );
  } else {
    return <Spin></Spin>;
  }
}

function EmployeeCard({
  employee,
  language,
  setPerson,
  setPage,
}: {
  employee: any;
  language: string;
  setPerson: (employee: Person) => void;
  setPage: (page: Pages) => void;
}) {
  const placeHolderPicture =
    "https://www.rarewine.dk/wp-content/uploads/636289323828937135_Afterlight_Edit-300x300.jpg";
  return (
    <div
      className={style.container}
      onClick={() => {
        setPerson(employee);
        setPage(Pages.Employee);
      }}
    >
      <Row justify="center">
        <Col>
          <Avatar
            className={style.avatar}
            src={employee.picture ? employee.picture : placeHolderPicture}
          />
        </Col>
      </Row>
      <Row
        justify="center"
        style={{ marginTop: 16 }}
        className={style.fullWidth}
      >
        <Col className={style.fullWidth}>
          <Row justify="center" className={style.fullWidth}>
            <Col className={style.fullWidth}>
              <div className={style.name}>{employee.name}</div>
            </Col>
          </Row>
          <Row justify="center" className={style.fullWidth}>
            <Col className={style.fullWidth}>
              <div className={style.position}>
                {employee.position[language]}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
