import { useMemo } from "react";
import { useLocale } from "../context/IntlContext";
import { useStrings } from "../hook/useStrings";

export function Trans(props: {
  msg: keyof MessageKeys;
  params?: Record<string, any>;
}) {
  const { strings } = useStrings();
  const locale = useLocale();

  const candidate = strings[locale][props.msg];
  const paramerized = useMemo(() => {
    if (!props.params) {
      return candidate;
    }

    let repl = candidate;
    for (const key in props.params) {
      repl = repl.replace(`{${key}}`, props.params[key]);
    }
    return repl;
  }, [candidate, props.params]);

  return paramerized;
}
