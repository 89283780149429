import { LeftOutlined, MailOutlined, RightOutlined } from "@ant-design/icons";
import { Card, Carousel, Col, Row } from "antd";
import { CarouselRef } from "antd/lib/carousel";
import React, { useRef } from "react";
import { Pages } from "../../frontpage/frontpage-view";
import style from "./carousel.module.scss";

export function CarouselCategory(props: {
  setPage: React.Dispatch<React.SetStateAction<Pages>>;
  receptionList: ReceptionList;
  setCategory: React.Dispatch<React.SetStateAction<Category | undefined>>;
}) {
  const carouselRef = useRef<CarouselRef | null>(null);
  const categoryPrPage = 4;
  const renderRows = (categories: Category[]) => {
    const jsx: JSX.Element[] = [];

    for (let i = 0; i < categories.length; i++) {
      if (i % categoryPrPage === 0) {
        const chunk = categories.slice(i, i + categoryPrPage);
        const temp = (
          <div key={i}>
            <Row justify="center">
              <Col span={16}>
                <Row gutter={[16, 16]}>
                  {chunk.map((category) => (
                    <Col span={12} key={category.name}>
                      {CategoryCard(category, props.setCategory, props.setPage)}
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
          </div>
        );
        jsx.push(temp);
      }
    }
    return jsx;
  };

  return (
    <div style={{ width: "100%" }}>
      <Card
        className={style.post}
        bodyStyle={{ padding: 0, height: "100%" }}
        onClick={() => props.setPage(Pages.Post)}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <MailOutlined
            style={{ fontSize: 20, color: "white", marginRight: 5 }}
          />

          <p
            style={{
              color: "white",
              textAlign: "center",
              fontSize: 20,
              margin: 0,
            }}
          >
            Post
          </p>
        </div>
      </Card>
      <Carousel dots={false} ref={carouselRef}>
        {renderRows(props.receptionList.categories)}
      </Carousel>
      {props.receptionList.categories.length > categoryPrPage && (
        <Row justify="center">
          <Col span={16}>
            <Row>
              <Col span={12}>
                <LeftOutlined
                  className={style.ArrowLeft}
                  onClick={() => carouselRef.current?.prev()}
                />
              </Col>
              <Col span={12}>
                <RightOutlined
                  className={style.ArrowRight}
                  onClick={() => carouselRef.current?.next()}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </div>
  );
}

const CategoryCard = (
  category: Category,
  setCategory: (name: Category) => void,
  setPage: (page: Pages) => void,
) => {
  return (
    <Card
      className={style.Group}
      bodyStyle={{ height: "100%", padding: 0 }}
      onClick={() => {
        setCategory(category);
        setPage(Pages.Carousel_Employee);
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <p
          style={{
            textAlign: "center",
            color: "white",
            fontSize: "32px",
            margin: 0,
          }}
        >
          {category.name}
        </p>
      </div>
    </Card>
  );
};
