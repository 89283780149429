import { createContext, useContext } from "react";

export const IntlContext = createContext<Locale>("en");
export function useLocale() {
  return useContext(IntlContext);
}

export function useCultureCode() {
  const locale = useContext(IntlContext);
  switch (locale) {
    case "da":
      return "da-DK";
    default:
      return "en-UK";
  }
}
