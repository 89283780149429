import React from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { Frontpage } from "./pages/frontpage/frontpage-view";

const router = createBrowserRouter([
  { path: "/", element: <></> },
  { path: "/:guid", element: <Frontpage /> },
]);

export function App() {
  return <RouterProvider router={router} />;
}
