export function useStrings() {
  const strings: Messages = {
    en: {
      header: "Welcome to RareWine",
      subheader: "Tap your host to notify them of your arrival",
      registrationLabel: "Registration number",
      noParking: "I have not parked a car",
      noParkingTitle: "Confirm you don't need parking",
      buttonParkingLicense: "I need a parking licence",
      buttonNoParkingLicense: "I do not need a parking licence",
      notify: "Notify {name} of your arrival",
      notifyResponseWithParking:
        "Your host has been notified, and you have registered parking for the license plate {registration} it expires at {expires}. Parking reference: {parkingReference}",
      notifyResponseNoParking: "Your host has been notified",
      notifyError:
        "Something went wrong, please call {name} directly at +45 {number}",
      noRegistration:
        "You didn't add a registration number, if you need to park click back and enter your license plate",
      notifyingHost: "We are notifying your host, please wait...",
      back: "Back",
      cancel: "Cancel",
      register: "Register",
      errorParking:
        "Your host has been notified; we could not register your vehicle. Ask your host for parking",
      registrationPlateError:
        "Your license plate number seems to be invalid, press notify to register anyway",
      registrationPlate: "You have entered the following plate number:",
      loadEmployeesError: "Could not load employees, please try again later.",
      retry: "Retry",
      postExplanation:
        "If you're here to deliver a parcel or likewise, please click the button below. An employee will be with you as soon as possible.",
      postCallEmployee: "Call an employee",
      postCalledEmployee:
        "An employee has been called and will be with you as soon as possble.",
    },
    da: {
      header: "Velkommen til RareWine",
      subheader: "Vælg den medarbejder du søger og meld din ankomst",
      registrationLabel: "Indtast dit reg.nr. for parkeringsbillet",
      noParking: "Jeg har ikke behov for en parkeringsbillet",
      noParkingTitle: "Jeg har ikke brug for parkering..",
      buttonParkingLicense: "Jeg skal bruge en p-licens",
      buttonNoParkingLicense: "Jeg skal ikke bruge en p-licens",
      notify: "Underret {name} om din ankomst",
      notifyResponseWithParking:
        "Din vært har fået besked om din ankomst, og der er registreret parkering til nummerpladen {registration} den udløber {expires}. Parkeringsreference: {parkingReference}",
      notifyResponseNoParking: "Din vært har fået besked om din ankomst",
      notifyError:
        "Der gik noget galt - ring direkte til {name} på +45 {number} istedet",
      noRegistration:
        "Du indtastede ikke en nummerplade. Hvis du har parkeret, tryk tilbage og indtast din nummerplade.",
      notifyingHost: "Vi giver din vært besked, vent venligst...",
      back: "Tilbage",
      cancel: "Afbryd",
      register: "Registrer",
      errorParking:
        "Din vært har fået besked om din ankomst, vi kunne ikke registrere dit køretøj, så spørg din host om parkering",
      registrationPlateError:
        "Din nummerplade ligner den er ugyldig, tryk notify for at registere alligevel",
      registrationPlate: "Du har indtastet følgende nummerplade:",
      loadEmployeesError: "Could not load employees, please try again later.",
      retry: "Prøv igen",
      postExplanation:
        "Hvis du skal aflevere en pakke eller noget lignede, så tryk på understående knap. Så kommer der en medarbejder hurtigtst muligt.",
      postCallEmployee: "Ring efter medarbejder",
      postCalledEmployee:
        "Der er blevet ringet til en medarbejder nu, de vil være hos dig hurtigtst muligt",
    },
  };

  return { strings };
}
