import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
import { Pages } from "../../pages/frontpage/frontpage-view";
import { Trans } from "../Trans";
import style from "./back-button.module.scss";

export function BackButton(props: {
  onClicked: (page: Pages) => void;
  previousPage: Pages;
}) {
  return (
    <Button
      style={{
        position: "fixed",
        top: "24px",
        left: "24px",
        color: "white",
        fontSize: "32px",
        height: "auto",
        width: "auto",
      }}
      size="large"
      className={style.btn}
      icon={<ArrowLeftOutlined />}
      onClick={() => props?.onClicked(props.previousPage)}
    >
      <Trans msg={"back"} />
    </Button>
  );
}
